import React, { useMemo } from "react"
import { Link, graphql } from "gatsby"
import PageBlog from "./subtemplates/subtemplate_blog"

export const query = graphql`
	query($paginacionSkip: Int!, $paginacionLimit: Int!, $blogCategory: [String!], $lang: String!){
    processwire {
      config {
        es {
          blog_categorias {
            ruta
            title
          }
        }
        en {
          blog_categorias {
            ruta
            title
          }
        }
        fr {
          blog_categorias {
            ruta
            title
          }
        }
        pt {
          blog_categorias {
            ruta
            title
          }
        }
      }
    }
    blogEntries: allSitePage(
      filter: {
        context: {
          template: {eq: "page_blog_entry"}, blog_categorias: {in: $blogCategory}, lang: {eq: $lang}
        }
      }
      limit: $paginacionLimit
      skip: $paginacionSkip
      ) {
      nodes {
        context {
          title
          ruta
          texto_blog_introduccion
          blog_categorias
          image{
            width
            
            url880
            url440
            url220
            url2000
            url1760
            url220webp
            url440webp
            url880webp
            
            url1760webp
            url2000webp
            url
            height
            description
            base64
            aspectratio
          }
          image_mobile{
            width
            
            url880
            url440
            url220
            url2000
            url1760
            url220webp
            url440webp
            url880webp
            
            url1760webp
            url2000webp
            url
            height
            description
            base64
            aspectratio
          }
        }
      }
    }
    blogArchivePages: allSitePage(filter: {context: {hasPagination: {eq: 1}}}) {
      nodes {
        context {
          title
          ruta
        }
        path
      }
    }
	}
`;
const PageBlogTemplate = (props) => (<PageBlog {...props} />)
export default PageBlogTemplate
